<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "BusinessRuleActions.EditBusinessRuleAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && businessRuleIsOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && businessRuleIsOk"
    class="form-businessruleactionupdaterecord-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-md-4">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "BusinessRuleActions.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="businessRuleActionData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-4">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "BusinessRuleActions.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          disabled
          :value="businessRuleActionData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col col-md-4">
        <label for="BusinessActionType" class="form-label"
          >{{
            $t(
              "BusinessRuleActions.BusinessActionType",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          disabled
          id="BusinessActionType"
          v-model="businessRuleActionType"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-12 mt-3">
        <label for="Description" class="form-label">{{
          $t(
            "BusinessRuleActions.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="businessRuleActionData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="2"
        ></textarea>
      </div>
      <div class="col col-md-12 mt-3">
        <label for="ResponseText" class="form-label">{{
          $t(
            "BusinessRuleActions.ResponseText",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="ResponseText"
          class="form-control"
          id="ResponseText"
          v-model="businessRuleActionData.responseText"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check isForcingSave">
        <label for="isForcingSave" class="form-label">{{
          $t(
            "BusinessRuleActions.IsForcingSave",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="isForcingSave"
          v-model="businessRuleActionData.isForcingSave"
        />
      </div>
      <div class="form-check isRunOnceForEachRecord">
        <label for="IsRunOnceForEachRecord" class="form-label">{{
          $t(
            "BusinessRuleActions.IsRunOnceForEachRecord",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsRunOnceForEachRecord"
          v-model="businessRuleActionData.isRunOnceForEachRecord"
        />
      </div>
      <div class="form-check">
        <label for="IsSynchAction" class="form-label">{{
          $t(
            "BusinessRuleActions.IsSynchAction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsSynchAction"
          v-model="businessRuleActionData.isSynchAction"
        />
      </div>
      <div class="form-check">
        <label for="DoNotTriggerBusinessRule" class="form-label">{{
          $t(
            "BusinessRuleActions.DoNotTriggerBusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DoNotTriggerBusinessRule"
          v-model="businessRuleActionData.doNotTriggerBusinessRule"
        />
      </div>
      <div class="form-check">
        <label for="DoNotCalculateFormulaFields" class="form-label">{{
          $t(
            "BusinessRuleActions.DoNotCalculateFormulaFields",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DoNotCalculateFormulaFields"
          v-model="businessRuleActionData.doNotCalculateFormulaFields"
        />
      </div>
      <div class="form-check">
        <label for="DoNotCalculateRollupFields" class="form-label">{{
          $t(
            "BusinessRuleActions.DoNotCalculateRollupFields",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="DoNotCalculateRollupFields"
          v-model="businessRuleActionData.doNotCalculateRollupFields"
        />
      </div>
      <div class="form-check">
        <label for="IsResponseReturnToClient" class="form-label">{{
          $t(
            "BusinessRuleActions.IsResponseReturnToClient",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsResponseReturnToClient"
          v-model="businessRuleActionData.isResponseReturnToClient"
        />
      </div>
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BusinessRuleActions.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="businessRuleActionData.isActive"
        />
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "BaseModelFields.CustomSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row align-items-end">
          <div class="col-4">
            <label for="" class="form-label required">{{
              $t(
                "BusinessRuleActions.SelectObjectYouWantToUpdateRecord",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              :allowEmpty="false"
              :selectedData="selectedActionCustomObject"
              :isParameters="true"
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryParentCustomObjects?customObjectPublicId={0}&businessRuleTriggerTypeId={1}',
                  this.$route.params.customObjectId,
                  this.businessRuleTriggerTypeId
                )
              "
              @onChange="onChangeForCustomObjectUpdate"
            />
          </div>
          <div class="col-4">
            <div id="RelatedLookupField">
              <label for="" class="form-label required">{{
                $t(
                  "BusinessRuleActions.SelectRelatedLookupField",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :selectedData="selectedLookupData"
                @onChange="onChangeLookupObject"
                :allowEmpty="false"
                :isParameters="true"
                :resetSelect="selectedLookupObjectReset"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryLookupFields?type=Lookup&parentId={0}&query={1}',
                    this.$route.params.customObjectId,
                    this.businessRuleActionData.actionCustomObjectPublicId
                  )
                "
              />
            </div>
          </div>
          <div class="col-4">
            <button
              type="button"
              class="btn btn-success getOtherFields float-end"
              id="getOtherFields"
              @click="getOtherFieldsAction"
            >
              <i class="bi bi-plus"></i>
              {{
                $t(
                  "BusinessRuleActions.GetOtherFields",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
        <div class="col col-md-12 mt-3">
          <Grid
            :settings="gridSettings"
            :columns="gridColumns"
            :rows="fieldList"
            @onFormulaEditorButtonClick="onFormulaEditorButtonClick"
          />
        </div>
      </div>
    </div>
  </form>
  <FormulaEditorModal
    :formulaField="formulaEditorFieldDto"
    @updateFormula="updateFormula"
  />
</template>
<script>
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
import $ from "jquery";
export default {
  name: "CustomObjectEditBusinessRuleActionUpdateRecord",
  components: {
    FormulaEditorModal,
  },
  data() {
    return {
      selectedLookupObjectReset: false,
      gridSettings: {
        action: "EditButtonFields",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: false,
        autoOrderProcess: true,
        isCommandColumnHide: true,

        buttons: [],
      },
      gridColumns: [
        {
          text: this.$t(
            "Fields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FieldType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldType",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.IsRequired",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isRequired",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Formula",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formula",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Buttons.Actions",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "",
          type: "string",
          visible: true,
          width: 0,
          template: "formulaEditorCommandTemplate",
          format: "",
          textAlign: "",
        },
      ],
      formulaEditorFieldDto: null,
      selectedLookupData: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save .btn-formula-editor",
      errors: [],
      createRecordFieldFormulasForEdit: [],
      businessRuleActionData: {},
      businessRuleIsOk: false,
      model: {},
      selectedActionCustomObject: [],
      businessRuleActionType: "",
      businessRuleTriggerTypeId: null,
      fieldsType: "Email|FormulaOrganizationalUnit",
      businessActionObject: [],
      detailForwardOrEditForwardType: false,
      detailRequestEnd: false,
      multipleSelect: this.$t(
        "Fields.MultipleSelect",
        {},
        { locale: this.$store.state.activeLang }
      ),
      select: this.$t(
        "Fields.Select",
        {},
        { locale: this.$store.state.activeLang }
      ),
      table: this.$t(
        "Fields.Table",
        {},
        { locale: this.$store.state.activeLang }
      ),
      fieldList: [],
      orjFieldList: [],
    };
  },
  methods: {
    nameKeyup() {
      if (String.isNullOrWhiteSpace(this.businessRuleActionData.name)) {
        this.businessRuleActionData.formulaName = "";
        return;
      }
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.businessRuleActionData.formulaName = String.replaceAlphanumeric(
        this.businessRuleActionData.formulaName,
        "_"
      ).toUpperCase();
    },
    onFormulaEditorButtonClick(rowData) {
      this.formulaEditorFieldDto = rowData;
    },
    updateFormula(formula, field) {
      this.formulaEditorFieldDto.formula = formula;

      var gridFieldIndex = this.fieldList.findIndex(
        (f) => f.publicId == field.publicId
      );

      var gridData = this.fieldList;
      gridData[gridFieldIndex].formula = formula;

      this.fieldList = [];
      this.fieldList = gridData;

      if (
        this.businessRuleActionData.fieldValueFormulas.some(
          (l) => l.key == field.publicId
        )
      ) {
        if (String.isNullOrWhiteSpace(formula)) {
          this.businessRuleActionData.fieldValueFormulas =
            this.businessRuleActionData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });
        } else {
          this.businessRuleActionData.fieldValueFormulas =
            this.businessRuleActionData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });

          this.businessRuleActionData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      } else {
        if (!String.isNullOrWhiteSpace(formula)) {
          this.businessRuleActionData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      }
    },
    onChangeForCustomObjectUpdate: function (selected) {
      this.selectedLookupObjectReset = !this.selectedLookupObjectReset;
      if (selected.key == null) {
        $(".getOtherFields").hide();
        this.businessRuleActionData.fieldValueFormulas = [];
        this.businessRuleActionData.actionCustomObjectPublicId = null;
        this.businessRuleActionData.actionCustomObjectName = null;
        this.businessRuleActionData.actionCustomObject = null;
      } else {
        $(".getOtherFields").show();
        this.businessRuleActionData.actionCustomObjectPublicId = selected.key;
        this.businessRuleActionData.actionCustomObjectName = selected.value;
        this.businessRuleActionData.actionCustomObject = selected.key;
        this.businessRuleActionData.fieldValueFormulas = [];
        this.getFields(selected.key);

        if (this.$route.params.customObjectId == selected.key) {
          document.getElementById("DoNotTriggerBusinessRule").checked = true;
          this.businessRuleActionData.doNotTriggerBusinessRule = true;
          document.getElementById("DoNotCalculateRollupFields").checked = true;
          this.businessRuleActionData.doNotCalculateRollupFields = true;
          if (selected.value == "CURRENT_RECORD") {
            $("#RelatedLookupField").hide();
          } else {
            $("#RelatedLookupField").show();
          }
        } else {
          $("#RelatedLookupField").show();
        }
        // if (this.$route.params.customObjectId == selected.key) {
        //   document.getElementById("DoNotCalculateFormulaFields").checked = true;
        //   this.businessRuleActionData.doNotCalculateFormulaFields = true;
        //   document
        //     .getElementById("DoNotCalculateFormulaFields")
        //     .setAttribute("disabled", "disabled");
        // } else {
        //   document
        //     .getElementById("DoNotCalculateFormulaFields")
        //     .removeAttribute("disabled", "disabled");
        // }
        // if (this.$route.params.customObjectId == selected.key) {
        //   document.getElementById("IsResponseReturnToClient").checked = true;
        //   this.businessRuleActionData.isResponseReturnToClient = true;
        //   document
        //     .getElementById("IsResponseReturnToClient")
        //     .setAttribute("disabled", "disabled");
        // } else {
        //   document
        //     .getElementById("IsResponseReturnToClient")
        //     .removeAttribute("disabled", "disabled");
        // }
      }
      this.businessRuleActionData.actionFieldPublicId = null;
      this.selectedLookupData = [];
    },
    onChangeLookupObject: function (selected) {
      this.businessRuleActionData.actionFieldPublicId = selected.key;
    },
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.businessActionObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getOtherFieldsAction() {
      this.fieldList = this.orjFieldList.sort(function (a, b) {
        return b.isRequired - a.isRequired;
      });
      $(".getOtherFields").hide();
    },
    getFields(coId, fieldValueFormulas = []) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldList?customObjectPublicId={0}", coId))
        .then((response) => {
          var multipleSelect = this.multipleSelect,
            select = this.select,
            table = this.table,
            isMultiLanguage = this.$store.getters._isMultiLanguage;

          if (response.data.itemCount > 0) {
            response.data.items.forEach((v) => {
              v.MultipleSelectText = multipleSelect;
              v.SelectText = select;
              v.TableText = table;
              v.IsMultiLanguage = isMultiLanguage;
            });
          }

          this.fieldList = response.data.items
            .filter(function (f) {
              return (
                f.isRequired ||
                (!f.isRequired &&
                  !String.isNullOrWhiteSpace(
                    fieldValueFormulas.find((d) => d.key == f.publicId)
                  ))
              );
            })
            .sort(function (a, b) {
              return b.isRequired - a.isRequired;
            });
          this.orjFieldList = response.data.items;

          if (!String.isNullOrWhiteSpace(fieldValueFormulas)) {
            this.fieldList.forEach((v) => {
              var selectedField = fieldValueFormulas.find(
                (f) => f.key == v.publicId
              );
              if (!String.isNullOrWhiteSpace(selectedField)) {
                v.formula = selectedField.value;
              }
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-businessruleactionupdaterecord-edit");
      form.addClass("was-validated");
      if (
        !this.$root.brActionValidity(form[0]) ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-BusinessRuleActionUpdateActionUpdateRecord", {
          ...this.businessRuleActionData,
        })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/BusinessRuleActions/" +
                this.$route.params.customObjectId +
                "&businessRuleId=" +
                this.$route.params.businessRuleId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getBusinessRuleActionData() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleActionGet?id={0}&coId={1}&businessRuleId={2}",
            this.$route.params.businessRuleActionId,
            this.$route.params.customObjectId,
            this.$route.params.businessRuleId
          )
        )
        .then((response) => {
          this.businessRuleIsOk = response.data.isOk;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            this.businessRuleActionData = response.data.businessRuleAction;
            this.selectedActionCustomObject.push({
              key: this.businessRuleActionData.actionCustomObjectPublicId,
              value: this.businessRuleActionData.actionCustomObjectName,
            });
            this.selectedLookupData.push({
              key: this.businessRuleActionData.actionFieldPublicId,
              value: this.businessRuleActionData.actionFieldName,
            });
            this.getFields(
              this.businessRuleActionData.actionCustomObjectPublicId,
              this.businessRuleActionData.fieldValueFormulas
            );
            if (
              this.businessRuleActionData.actionCustomObjectPublicId ==
              this.businessRuleActionData.customObjectPublicId
            ) {
              if (
                this.businessRuleActionData.actionCustomObjectName ==
                "CURRENT_RECORD"
              ) {
                $("#RelatedLookupField").hide();
              }
            }
          } else {
            this.errors.push(response.data.message);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.getBusinessRuleActionData();
    this.businessRuleTriggerTypeId = localStorage.getItem(
      "businessRuleTriggerTypeId"
    );
    this.businessRuleActionType = localStorage.getItem(
      "businessRuleActionType"
    );
  },
};
</script>
